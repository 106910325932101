import axios from "axios";

export async function registerUser(userData) {
  const reqUrl = "http://localhost:8080/api/auth/signup";
  const result = await axios.post(reqUrl, userData);
  return result.data;
}

export async function loginUser(userData) {
  const reqUrl = "http://localhost:8080/api/auth/login";
  const result = await axios.post(reqUrl, userData);
  return result.data;
}

export async function uploadFile(formData) {
  const reqUrl = "http://localhost:8080/api/public/excelParser";
  const result = await axios.post(reqUrl, formData);
  return result.data;
}

export async function postQuery(data) {
  // const reqUrl = 'https://api.networthtracker.in/api/users/marketingUser';
  const reqUrl = "https://c561-13-233-16-58.ngrok-free.app/addToWaitlist";
  const result = await axios.post(reqUrl, data);
  return result;
}
